import { EntryCollection } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';
export class BlogContentModel {
    blogTitle:string = '';
    blogItems:any = []

    constructor(entries?: EntryCollection<any>) {
        if (entries && entries.items.length > 0 && entries.items[0].fields) {
            
            const contentDetail: any = entries.items[0].fields;
            if(contentDetail.identifier) {
                this.blogTitle = contentDetail.identifier;
            }
            if(contentDetail.blogList.length > 0) {
                contentDetail.blogList?.forEach((blogItem:any)=> {
                    this.blogItems.push({
                        id: blogItem.sys.id,
                        title: blogItem?.fields?.title,
                        content: documentToHtmlString(blogItem.fields?.content, richTextOptions),
                        author: blogItem?.fields?.auhtor,
                        date: blogItem?.fields?.date,
                        imageUrl: blogItem?.fields?.image?.fields?.file?.url

                 })
                });
               
            }
        }
    }
}
import { EntryCollection,Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';
export class BlogItemContentModel {
    id:string = '';
    title:string = '';
    content:string = '';
    author:string = '';
    date:string = '';
    imageUrl:string = '';
    constructor(entries?: any) {
        if (entries && entries.fields) {
                        this.id = entries.sys.id;
                        this.title = entries?.fields?.title;
                        this.content = documentToHtmlString(entries.fields?.content, richTextOptions);
                        this.author = entries?.fields?.auhtor;
                        this.date = entries?.fields?.date;
                        this.imageUrl = entries?.fields?.image?.fields?.file?.url;
        }
    }
}
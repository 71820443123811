import { Injectable } from '@angular/core';
import { createClient, Entry, EntryCollection } from 'contentful';
import { from, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { contentful } from '../../shared/models/contentful/constants.contentful';
import { catchError, map, mergeMap, retryWhen, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  constructor() { 
    //console.log(environment)
    //console.log(environment.environment)
  }

  private cdaClient = createClient({
    space: environment.space,
    accessToken: environment.accessToken,
    environment: environment.environment
  });

  // getAllEntries(contentTypeId: string): Observable<EntryCollection<any>> {
  //   return from(
  //     this.cdaClient.getEntries<any>({ content_type: contentTypeId, include: 10 })
  //       .catch(error => {
  //         console.error('Contentful API Error:', error);
  //         throw error;
  //       })
  //   );
  // }

  getMenuEntry(contentTypeId: string, pageName: string): Observable<EntryCollection<any>> {
    return from(
      this.cdaClient.getEntries<any>({ content_type: contentTypeId, limit: 1, "fields.name": pageName, include: 10 })
        .catch(error => {
          console.error('Contentful API Error:', error);
          throw error;
        })
    );
  }
  getEntryById(id: string): Observable<EntryCollection<any>> {
    return from(
      this.cdaClient.getEntry(id).then((entry:any) => {
            return entry; 
      }).catch(error => {
            throw error;
          })
    );
  }
  
  getEntry(contentTypeId: string, pageName: string): Observable<EntryCollection<any>> {
    return from(
      this.cdaClient.getEntries<any>({ content_type: contentTypeId, limit: 1, "fields.identifier": pageName, include: 10 })
        .catch(error => {
          console.error('Contentful API Error:', error);
          throw error;
        })
    );
  }

  getContentfulSysFields(): any {
    return contentful;
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentfulService } from 'src/app/core/service/contentful.service';
import { BlogItemContentModel } from 'src/app/core/model/blogItem-content.model';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {

  data: any = [];
  id: any;
  filterData: any;
  pageContent!: BlogItemContentModel;
  pageHeader!: PageHeaderContentModel;


  constructor(private HttpClient: HttpClient, private router: Router, private activateRouter: ActivatedRoute, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {      
    this.activateRouter.queryParams.subscribe( params => { 
      if(params['Id'] == null || params['Id'] == undefined) {  
          const id = sessionStorage.getItem('newsId');
          this.id = id;
      }else {
        this.id = params['Id'];
        sessionStorage.setItem('newsId', this.id); 
      }
      this.loadBodyEntries();
    }); 
  }


  loadBodyEntries() {
    this.contentfulService.getEntryById(this.id).subscribe(
      (entry) => {
        this.pageContent = new BlogItemContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }
 
}
